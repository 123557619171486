import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { navigate } from 'gatsby';

import * as styles from './hero.module.css'

export const ROUTES = [
  { to: '/login', title: 'login' },
  { to: '/register', title: 'register' },
];

const Hero = ({ image, title, content , logo, email}) => (
  <div className={styles.hero}>
    {image && (
      <GatsbyImage alt="" style={{backroundColor: 'black', opacity: '0.8'}} className={styles.image} image={image} />
    )}

      <div className={styles.details}>
        <GatsbyImage className={styles.logo} image={logo} alt="" />
        <div className={styles.banner_content}>
          <h1 className={styles.title}>{title}</h1>
            {content && (
              <div className={styles.content}>{renderRichText(content)}</div>
            )}
            <div className={styles.contact_container}>
              <a className={styles.contact_link} href="mailto:info@timestables.nl">info@timestables.nl</a>
              <a className={styles.contact_link} href="tel:0713314241">071-3314241</a>
            </div>

            <div>
              <button onClick={() => navigate('/login')} className={styles.button} >Inloggen</button>
              <button onClick={() => navigate('/register')} className={styles.button_transparent}>Registreren</button>
            </div>
        </div>

      </div>


  </div>
)

export default Hero
