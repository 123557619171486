// extracted by mini-css-extract-plugin
export var banner_content = "hero-module--banner_content--DIRFI";
export var button = "hero-module--button--HwtA+";
export var button_transparent = "hero-module--button_transparent--f5rT+";
export var contact_container = "hero-module--contact_container--Y8Sl5";
export var contact_link = "hero-module--contact_link--qSg1v";
export var content = "hero-module--content--u1cO9";
export var details = "hero-module--details--WWO7r";
export var hero = "hero-module--hero--JreNK";
export var image = "hero-module--image--wE2lP";
export var logo = "hero-module--logo--KE7Bn";
export var title = "hero-module--title--+uEef";