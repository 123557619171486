import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import Container from './container'
import * as styles from './article-preview.module.css'

const ArticlePreview = ({ posts, slidesAmount }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <Container>
      <ul className={styles.articleList}>
        <Swiper
          slidesPerView={slidesAmount}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
        {posts.map((post) => {
          return (
            <SwiperSlide key={post.slug}>
                <li style={{width: '100%'}}>
              <Link to={`/team/${post.slug}`} className={styles.link}>
                <GatsbyImage alt="" image={post.heroImage.gatsbyImageData} />
                <h2 className={styles.title}>{post.title}</h2>
              </Link>
              <div>
                {post.description?.raw && renderRichText(post.description)}
              </div>

            </li>
              
            </SwiperSlide>

          )
        })}
        </Swiper>

      </ul>
    </Container>
  )
}

export default ArticlePreview
