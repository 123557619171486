import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import ImageTextBlock from '../components/imageTextBlock/imageTextBlockView'
import { Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper";
import HorseSlide from '../components/horseSlide/horseSlideView'
import useWindowDimensions from '../hooks/useWindowDimensions'

// class RootIndex extends React.Component {

const RootIndex = (props) => {  
    let slidesAmount = 3
    let ytvideo;
    const {width} = useWindowDimensions();
    if (width > 1200){
      slidesAmount = 3;
    }
    else{
      slidesAmount = 1;
    }
    const posts = props.data.allContentfulHomepage.nodes[0].onsTeam;
    const author = props.data.allContentfulHomepage.nodes[0].banner;
    const introduction = props.data.allContentfulHomepage.nodes[0].introduction;
    const horses = props.data.allContentfulHomepage.nodes[0].onzePaarden;

    if(props.data.allContentfulHomepage.nodes[0].youtubeVideoId !== null && width > 768){
      const video_src = "https://www.youtube.com/embed/" + props.data.allContentfulHomepage.nodes[0].youtubeVideoId;
      ytvideo = <iframe title='ytplayer' id="ytplayer" type="text/html" width="640" height="360"
      src={video_src} frameBorder="0" allowFullScreen={true}></iframe>
    }
    else if(props.data.allContentfulHomepage.nodes[0].youtubeVideoId !== null && width < 768){
      const video_src = "https://www.youtube.com/embed/" + props.data.allContentfulHomepage.nodes[0].youtubeVideoId;
      ytvideo =             
      <iframe title='ytplayer' id="ytplayer" type="text/html" width="360" height="200"
      src={video_src}
      frameBorder="0"
      allowFullScreen={true}></iframe>
    }
    else{
      ytvideo = <div></div>
    }

    return (
      <Layout title={props.data.allContentfulHomepage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign:'center'}}>{props.data.allContentfulHomepage.nodes[0].title}</h1>
        <Hero
          image={author.image.gatsbyImageData}
          title={author.name}
          content={author.shortBio}
          logo={author.logo.gatsbyImageData}
          email={author.email}
        />
        <h2 style={{textAlign: 'center'}}>Ons Team</h2>
        <ArticlePreview posts={posts} slidesAmount={slidesAmount} />
        <ImageTextBlock heading={introduction.heading} subHeading={introduction.subHeading} image={introduction.image?.gatsbyImageData} content={introduction.mainText}/>
        <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '100px', flexDirection: 'column'}}>
            <h2>Facebook</h2>
            <iframe src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTimeStablesNieuweWetering&tabs=timeline&width=380&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"} width="380" height="500"  title="fb_feed" scrolling="no"  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            <h2 style={{marginTop: '100px'}}>Youtube</h2>
            <div >
              {ytvideo}
            </div>
        </div>

        <h2 style={{textAlign: 'center', marginTop: '100px'}}>Onze Paarden</h2>  
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='swiper_container' >
            <Swiper
            slidesPerView={slidesAmount}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            >
              {(horses).map((horse) => <SwiperSlide key={horse.heading}><HorseSlide heading={horse.heading} subHeading={horse.subheading} description={horse.shortDescription} image={horse.image.gatsbyImageData} slug={horse.slug} ></HorseSlide></SwiperSlide>) }
            </Swiper>
          </div>
        </div>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <button onClick={() => navigate('/contact')} style={{height: '50px', width: '250px', marginTop: '75px'}} className='button'>Contact</button>
        </div>
      </Layout>
    )
  }


export default RootIndex


export const pageQuery = graphql`
query MyQuery {
  allContentfulHomepage {
    nodes {
      title
      banner {
        title
        shortBio {
          raw
        }
        image {
          gatsbyImageData
        }
        logo {
          gatsbyImageData
        }
      }
      introduction {
        heading
        subHeading
        mainText {
          raw
        }
        image {
          gatsbyImageData
        }
      }
      onzePaarden {
        youtubeVideoId
        subheading
        heading
        slug
        shortDescription
        description {
          raw
        }
        image {
          gatsbyImageData(width: 300, height: 300)
        }
      }
      youtubeVideoId
      onsTeam {
        title
        slug
        heroImage {
          gatsbyImageData
        }
        description {
          raw
        }
        body {
          raw
        }
      }
    }
  }
}

`